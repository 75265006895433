export const COLORS: any = {
    Safe: "bg-[#E6F5F0] text-[#34BB7A]",
    Low: "bg-[#E6F5F0] text-[#34BB7A]",
    Moderate: "bg-[#FEF5E6] text-[#F99F01]",
    Caution: "bg-[#FFE4D9] text-[#FF4D00]",
    High: "bg-[#FFE4D9] text-[#FF4D00]",
    Danger: "bg-[#FAE8E8] text-[#DF1525]",
    // Very_high: "bg-[#FAE8E8] text-[#DF1525]",
    'Very high': "bg-[#FAE8E8] text-[#DF1525]",
};

export const PORTCOLORS: any = {
    "Low volatility": "bg-[#E6F5F0] text-[#34BB7A]",
    "Moderate volatility": "bg-[#FEF5E6] text-[#F99F01]",
    "High volatility": "bg-[#FFE4D9] text-[#FF4D00]",
    "Very High volatility": "bg-[#FAE8E8] text-[#DF1525]"
}


export const firms = [
    [
        "한국투자증권",
        "키움증권",
        "NH투자증권",
        "KB증권",
        "신한투자증권",
        "하나증권",
        "미래에셋증권",
        "카카오페이증권",
        "DB금융투자",
        "토스증권",
        "IBK투자증권",
        "SK증권",
        "상상인증권",
        "교보증권",
        "대신증권",
        "메리츠증권",
        "부국증권",
        "삼성증권",
        "신영증권",
        "유안타증권",
        "유진투자증권",
        "한화투자증권",
        "이베트스트증권",
        "한양증권",
        "유화증권",
        "없음",
    ],
    [
        "Korea Investment & Securities",
        "KIWOOM Securities",
        "NH Investment & Securities",
        "KB Securities",
        "Shinhan Securities",
        "Hana Financial Investment",
        "Mirae Asset Securities",
        "Kakao Pay Securities",
        "DB Financial Investment",
        "Toss investment",
        "IBK Securities",
        "SK Securities",
        "Sangsangin Investment & Securities",
        "Kyobo Securities",
        "Daishin Securities",
        "Meritz Securities",
        "Bookook Securities",
        "Samsung Securities",
        "Shinyoung Securities",
        "Yuanta Securities Korea",
        "Eugene Investment & Secs",
        "Hanwha investment&securities",
        "EBEST Investment & Securities",
        "Hanyang Securities",
        "Yuhwa Securities",
        "Nothing",
    ],
];