import Image from "next/image";
import useHandleImageError from "utils/useHandleImageError";

interface props {
  src: string;
  flag?: string;
  HR_ITEM_NM: string;
}

const Img = ({ src, HR_ITEM_NM, flag }: props) => {
  const handleImageError = useHandleImageError();
  return (
    <div className="relative w-[40px] h-[40px] flex justify-center items-center shrink-0">
      <Image
        unoptimized
        quality={100}
        alt="img"
        height={40}
        width={40}
        src={src}
        style={{
          borderRadius: "100%",
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        onError={(event) => handleImageError(event, HR_ITEM_NM)}
      />
      {flag && (
        <div className="absolute bottom-0 right-0 w-[15px] h-[10px] ">
          <Image
            alt="img"
            height={100}
            width={100}
            src={flag}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            className=" bg-gray-400 rounded-sm border border-white box-content"
          />
        </div>
      )}
    </div>
  );
};

export default Img;
